import axios from 'axios';

const instance = axios.create({
    //baseURL: 'https://react-my-burger-cea78.firebaseio.com/'http://smms/API
    //baseURL:'http://smms/API/'
    
    //baseURL:'https://localhost:44338/'
    baseURL:'/API/'
    
    //http://localhost:3000/Rooms/GetMasterChangeStatusList
    //https://localhost:44338/Rooms/GetMasterChangeStatusList
    //http://localhost:3000/API/Rooms/GetMasterChangeStatusList
});

export default instance;