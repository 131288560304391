import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import * as actions from "../../store/actions/index";
import { updateObject, checkValidity } from "../../shared/utility";
const M = require("../../styles/img/miscIcon.svg");

class Auth extends Component {
  state = {
    controls: {
      email: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "ITS / Username",
          autoFocus: localStorage.getItem("LoginID") != null ? false : true,
        },
        value: "",
        validation: {
          required: false,
          isEmail: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: "Password",
          autoFocus: localStorage.getItem("LoginID") != null ? true : false,
        },
        value: "",
        validation: {
          required: false,
          //minLength: 6,
        },
        valid: false,
        touched: false,
      },
    },
    isRememberMe: false,
    validationerror: "",
    isCleared: false,
    loading: false,
  };

  componentDidMount() {
    if (this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
    if (localStorage.getItem("LoginID") != null) {
      this.setState({ isRememberMe: true });
      const updatedControls = updateObject(this.state.controls, {
        email: updateObject(this.state.controls["email"], {
          value: localStorage.getItem("LoginID"),
          valid: checkValidity(
            localStorage.getItem("LoginID")
            //,this.state.controls["email"].validation
          ),
          touched: true,
        }),
      });
      this.setState({ controls: updatedControls });
    }
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.controls[controlName].validation
        ),
        touched: true,
      }),
    });
    this.setState({ controls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.setState({ isCleared: false });
    if (this.state.controls["email"].value.trim() === "") {
      this.setState({ validationerror: "ITS / Username required" });
    } else if (this.state.controls["password"].value.trim() === "") {
      this.setState({ validationerror: "Password required" });
    } else {
      this.setState({ validationerror: "", loading: true });
      this.props.onAuth(
        this.state.controls.email.value,
        this.state.controls.password.value,
        this.state.isRememberMe
      );
    }
  };

  checkboxHandler = () => {
    this.setState((prevState) => {
      return { isRememberMe: !prevState.isRememberMe };
    });
  };
  clearDataHandler = () => {
    const cleardata = updateObject(this.state.controls, {
      email: updateObject(this.state.controls["email"], {
        value: "",
        touched: false,
      }),
      password: updateObject(this.state.controls["password"], {
        value: "",
        touched: false,
      }),
    });
    this.setState({
      controls: cleardata,
      //isKhidmatGuzar: true,
      validationerror: "",
      isCleared: true,
    });
  };

  render() {
    let AuthLoading = <Spinner />;
    let errorMessage = "";
    if (this.state.validationerror.trim() !== "") {
      errorMessage = <p className="error">{this.state.validationerror}</p>;
    }
    if (
      this.props.error &&
      this.state.validationerror.trim() === "" &&
      !this.state.isCleared
    ) {
      errorMessage = <p className="error"> {this.props.error}</p>;
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (

      // <div>
        
     

      <article className="align-items-center h-100 justify-content-center ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <article className="loginBox ">
                <h1>
                <img alt='' src={M} style={{paddingRight:"10px"}}></img>
                  Safar <span>53</span>
                </h1>
                {authRedirect}

                {this.props.loading ? AuthLoading : null}
                <form className="formWrapper" onSubmit={this.submitHandler}>
                  {errorMessage}
                  <Input
                    key={"email"}
                    classType="form-control userNameField"
                    elementType={this.state.controls["email"].elementType}
                    elementConfig={this.state.controls["email"].elementConfig}
                    value={this.state.controls["email"].value}
                    invalid={!this.state.controls["email"].valid}
                    shouldValidate={this.state.controls["email"].validation}
                    touched={this.state.controls["email"].touched}
                    changed={(event) =>
                      this.inputChangedHandler(event, "email")
                    }
                  />
                  <Input
                    key={"password"}
                    classType="form-control passwordField"
                    elementType={this.state.controls["password"].elementType}
                    elementConfig={
                      this.state.controls["password"].elementConfig
                    }
                    value={this.state.controls["password"].value}
                    invalid={!this.state.controls["password"].valid}
                    shouldValidate={this.state.controls["password"].validation}
                    touched={this.state.controls["password"].touched}
                    changed={(event) =>
                      this.inputChangedHandler(event, "password")
                    }
                  />
                  <div className=" d-flex">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={this.state.isRememberMe}
                        onChange={this.checkboxHandler}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck1"
                      >
                        Remember me
                      </label>
                    </div>
                    
                  </div>
                  <button
                    type="submit"
                    className="btn btn-blue"
                  >
                    Login
                  </button>
                </form>
              </article>
            </div>
          </div>
        </div>
        <footer>
          {/* <nav className="navbar fixed-bottom navbar-light "> */}
            <div className="loginfooter">
              Designed &amp; Developed by{" "}
              <a
                href="https://babjisoftech.com/"
                target="_blank"
                className="text-underline"
                rel="noopener noreferrer"
              >
                Babji Softech
              </a>
            </div>
          {/* </nav> */}
        </footer>
      </article>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, isRememberMe) =>
      dispatch(actions.auth(email, password, isRememberMe)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
