export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules, label) => {
    let isValid = true;
    let validationMessage="";
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        if(rules.IsDate && value!=="" && value!==undefined)
        {isValid=true}
        else if (value === undefined) {
            isValid=false
        }
        else
        {isValid = String(value).trim() !== "" && isValid;}
        
        if(!isValid)
        {
            if(!rules.isCustomizedMessage)
            {
            validationMessage = label +" required"; 
            }
            else
            {
                 validationMessage = rules.CustomizedMessage
            }
        }
    }

    if ( rules.isEmail && value !=="" && value!==undefined) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
        if(validationMessage==="" && !isValid && !rules.isCustomizedMessage)
        {
            validationMessage=label+": Invalid email format";
        }
    }

    if ( rules.isNumeric && value !=="" && value!==undefined) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
        if(validationMessage==="" && !isValid && !rules.isCustomizedMessage)
        {
            validationMessage=label+": Only numeric input accepted";
        }
    }
    if (rules.IsDate && value !=="" && value!==undefined)
    {
        const pattern=/(19|20)[0-9][0-9]-(0[0-9]|1[0-2])-(0[1-9]|([12][0-9]|3[01]))T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/;
        if(value!=="" && value !== undefined)
        {
            isValid = pattern.test( value.toISOString() ) && isValid
        if(validationMessage==="" && !isValid && !rules.isCustomizedMessage)
        {
            validationMessage=label+": Invalid date format";
        }
        }
        else{validationMessage=label+":Invalid date format";}
    }

    if ( rules.minLength  && value !=="" && value!==undefined) {
        isValid = String(value).length >= rules.minLength && isValid
        if(validationMessage==="" && !isValid && !rules.isCustomizedMessage)
        {
            validationMessage= label+": Minimum "+rules.minLength+" "+(rules.isNumeric===true?"digit(s)":"letter(s)") +" required";
        }
    }

    if ( rules.maxLength  && value !=="" && value!==undefined) {
        isValid = String(value).length <= rules.maxLength && isValid
        if(validationMessage==="" && !isValid && !rules.sCustomizedMessage)
        {
            validationMessage=label+": Maximum length should be "+rules.maxLength+" "+(rules.isNumeric===true?"digit(s)":"letter(s)");
        }
    }

    return [true,validationMessage];
}
