import React, { Component } from "react";
import { connect } from "react-redux";

import Aux1 from "../Aux1/Aux1";
import '../../styles/css/bootstrap.min.css';
// import '../../styles/css/jquery.mCustomScrollbar.min.css';
import '../../styles/css/customScroll.css';
//import "react-datepicker/dist/react-datepicker.css";
//import '../../styles/css/tooltip.css';
//import '../../styles/css/style.css';
import '../../styles/css/styles.css';
import '../../assets/stylesheets/calendar.css';
// import '../../assets/stylesheets/_fonts.sass';
// import '../../assets/stylesheets/_calendar.sass';
// import '../../assets/stylesheets/_day.sass';
// import '../../assets/stylesheets/_main.sass';
// import '../../assets/stylesheets/_miqaat-list.sass';
// import '../../assets/stylesheets/_modal.sass';
// import '../../assets/stylesheets/_month-row.sass';
// import '../../assets/stylesheets/_year-row.sass';


//import "@deskpro/react-datepicker-hijri/dist/react-datepicker.css";
import Login from '../../containers/Auth/Auth';

class Layout extends Component {
  
  render() {
    if(this.props.isAuthenticated){
      return(
        <Aux1>
        {this.props.children}
        </Aux1>
      )
    }
    else {
        return (
            <Aux1>
              <Login></Login>
            </Aux1>
          );
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

export default connect(mapStateToProps)(Layout);
