import React, { lazy, Suspense, Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "./hoc/Layout/Layout";
import Logout from "./containers/Auth/Logout/Logout";
import Aux1 from './hoc/Aux1/Aux1';
import * as actions from "./store/actions/index";
import CacheBuster from './CacheBuster';
import "./components/UI/Spinner/Spinner.css"

const Auth = lazy(() => import("./containers/Auth/Auth"));
//const Launch = lazy(() => import('./components/Launch/Launch'));
const Home = lazy(() => import("./components/Home/Home"));
const AddCity = lazy(() => import("./components/AddCity/AddCity"));
const AddMiqaat = lazy(() => import("./components/AddMiqaat/AddMiqaat"));
const AddSafar = lazy(() => import("./components/AddSafar/AddSafar"));
const SafarDetails = lazy(() => import("./components/SafarDetails/SafarDetails"));
const SafarList = lazy(() => import("./components/SafarList/SafarList"));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Suspense
        fallback={
          <div className="loader h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" exact component={Auth} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Suspense
          fallback={
            <div className="loader h-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  {/* <span className="sr-only">Loading...</span> */}
                </div>
              </div>
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/city" component={AddCity} />
            <Route exact path="/miqaat" component={AddMiqaat} />
            <Route exact path="/safar" component={AddSafar} />
            <Route exact path="/details" component={SafarDetails} />
            <Route exact path="/list" component={SafarList} />
            {/* <Route exact path="/Launch" component={Launch} /> */}
            <Route path="**" render={
                () => (
                    <article>
                        <h1 className="text-danger">No Route Configured!</h1>
                        <h4 className="text-danger">Please check your Route Configuration</h4>
                    </article>
                )
            } />
          </Switch>
        </Suspense>
      );
    }

    return (
      
      <Aux1>
        <CacheBuster>
        {() => {

              fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
              .then((response) => response.json())
              .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                global.metaVersion = latestVersion;
               
                if(latestVersion !== currentVersion)
                {
                  const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

                  if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    console.log('Clearing cache and hard reloading...')
                    
                  if (window.caches !== undefined) {
                
                    caches.keys().then(async function(names) {
                      await Promise.all(names.map(name => caches.delete(name)));
                      });
                  }

                  // delete browser cache and hard reload
                  window.location.reload(true);
                    
                  } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    
                  }
              }
              });
          
          return (
           
              <Layout>{routes}</Layout>
            
          );
        }}
      </CacheBuster>
        
      </Aux1>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
